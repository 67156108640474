/* eslint-disable global-require */
/* eslint-disable prefer-destructuring */
import moment from 'moment'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-select.scss'
import { IDR } from '@/libs/currency'
import { mapState } from 'vuex'
import phoneInput from '@/views/components/input/phoneInput.vue'
import ModalBundlingQuantity from '@/views/components/modal/ModalBundling.vue'

export default {
  components: { vSelect, ModalBundlingQuantity, phoneInput },
  props: {
    idOrder: 0,
  },
  data() {
    return {
      IDR,
      address: [],
      customerName: '',
      customerPhone: '',
      customerAddress: '',
      destination: null,
      product: [],
      productList: [],
      fieldOrder: [
        { key: 'no', label: 'No' },
        { key: 'product_name', label: 'Nama Produk' },
        { key: 'price', label: 'Harga Satuan' },
        { key: 'qty', label: 'Jumlah' },
        { key: 'subtotal', label: 'Sub Total' },
      ],
      loadingCalculate: false,
      itemOrder: [],
      isCalculateOnExpedition: false,
      paymentMethod: null,
      paymentHistory: false,
      listPayment: ['COD', 'BANK TRANSFER'],
      rekening: null,
      listRekening: [],
      totalRekening: 0,
      bankName: null,
      bankAccountName: null,
      bankAccountNo: null,
      shipping: null,
      listShipping: [],
      shippingCost: null,
      serviceFee: null,
      serviceFeePercentage: null,
      weight: null,
      cashback: null,
      cashbackPercentage: null,
      potonganSaldo: false,
      discount: 0,
      biayaLain: false,
      jenisBiayaLain: null,
      sesuaiNominal: 0,
      bebankanCustomer: 0,
      additionalCost: 0,
      subTotal: null,
      netProfit: null,
      grandTotal: null,
      newGrandTotal: null,
      isValidate: false,
      formData: null,

      dataErrSubmit: null,
      loadingOptionExpedition: false,

      loadingWrapperOtherCost: false,
      messageErrorLengthCustomerName: false,
      messageErrorAddressDetail: false,
      oldGrandTotal: null,
      loadingSearchDestination: false,

      itemsCustomLabel: [],
      customLabel: null,

      editMode: false,
      orderId: null,
      orderDetails: [],
      shippingFromDetailEdit: {},
      loadingEditOrder: false,
      cartProductId: [],
      idCartDelete: [],
      isMassOrder: null,
      destinationPreview: null,

      itemsEditOrder: null,
      soldBy: {},
      soldById: 0,
      CustomLabelId: null,
      soldByList: [],
      orderNotes: '',
      dateOrder: moment().format('YYYY-MM-DD'),
      checkedInsurance: false,
      insurancePrice: 0,

      valuePhone: '',
      requireCustomerPhone: true,
    }
  },
  computed: {
    ...mapState('dashboard', ['profile']),
  },
  mounted() {
    this.fetchDataDetailOrder()
  },
  methods: {
    formatDate(date) {
      const monthName = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']
      const day = moment(date).format('DD')
      const month = moment(date).format('M')
      const year = moment(date).format('YYYY')
      return `${day} ${monthName[month - 1]} ${year}`
    },
    getSalesTrackingList() {
      this.$http_komship.get('/v1/tracking-sales/list')
        .then(response => {
          this.soldByList = response.data.data
          this.soldBy = this.soldByList.find(item => {
            if (item.id === this.soldById) {
              return true
            }
            return false
          })
          if (!this.soldBy && this.soldByList.length > 0) {
            this.soldBy = this.soldByList[0]
          }
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.data.message,
              variant: 'danger',
            },
          })
        })
    },
    getCustomLabel() {
      this.$http_komship.get(`/v1/custom-labels/${this.profile.partner_id}`)
        .then(response => {
          const { data } = response.data
          this.itemsCustomLabel = data
          this.customLabel = this.itemsCustomLabel.find(item => {
            if (item.id === this.CustomLabelId) {
              return true
            } if (item.is_default === 1) {
              return true
            }
            return false
          })
          if (!this.customLabel && this.itemsCustomLabel.length > 0) {
            this.customLabel = this.itemsCustomLabel[0]
          }
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          })
        })
    },
    checkValidation() {
      if (this.dateOrder && this.customerName && this.customerPhone && this.customerAddress && (this.customLabel || this.soldBy)) {
        this.isValidate = true
      } else {
        this.isValidate = false
      }

      this.formData = {
        order_id: this.idOrder,
        partner_id: this.profile.partner_id,
        order_date: this.dateOrder,
        customer_name: this.customerName,
        customer_phone: `0${this.customerPhone}`,
        customer_address: this.customerAddress,
      }

      if (this.profile.partner_is_custom_label) {
        this.formData.custom_label_id = this.customLabel.id
      }
      if (this.profile.partner_is_tracking_sales) {
        this.formData.tracking_sales_id = this.soldBy === undefined ? 0 : this.soldBy.id
      }
      if (this.profile.partner_is_order_notes) {
        this.formData.order_notes = this.orderNotes
      }
    },
    async submit(order) {
      this.checkValidation()
      if (this.isValidate) {
        await this.$http_komship.put(`v2/order/${this.profile.partner_id}/update/${this.orderId}`, this.formData)
          .then(() => {
            this.$swal({
              title: '<span class="font-weight-bold h4">Berhasil Update Order</span>',
              imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/success.svg',
              confirmButtonText: 'Oke',
              confirmButtonClass: 'btn btn-primary',
            }).then(() => {
              if (order) {
                window.location.reload()
              } else {
                this.$router.push('/data-order')
              }
            })
          })
          .catch(err => {
            this.dataErrSubmit = err.response.data
            this.dataErrSubmit = err.response.data
            if (
              this.dataErrSubmit.message === 'Please Topup to continue your store Order.'
              || this.dataErrSubmit.message === 'Sorry, your balance is not enough to make a postage payment'
              || this.dataErrSubmit.message === 'Sorry, there is not enough stock to continue the order'
            ) {
              let nameButton = ''
              let titleAlert = ''
              if (
                this.dataErrSubmit.message
                === 'Please Topup to continue your store Order.'
              ) {
                nameButton = 'Cek Saldo'
                titleAlert = 'Mohon Maaf, saldo anda tidak mencukupi untuk membuat order. Silahkan cek kembali saldo anda.'
              } else if (
                this.dataErrSubmit.message
                === 'Sorry, your balance is not enough to make a postage payment'
              ) {
                nameButton = 'Cek Saldo'
                titleAlert = 'Mohon Maaf, saldo anda tidak mencukupi untuk membuat order. Silahkan cek kembali saldo anda.'
              } else if (
                this.dataErrSubmit.message
                === 'Sorry, there is not enough stock to continue the order'
              ) {
                nameButton = 'Cek Produk'
                titleAlert = 'Mohon maaf, stok produk kamu tidak mencukupi untuk membuat orderan ini. Silahkan tambahkan stok produk terlebih dahulu'
              }
              this.$swal({
                title: `<span class="font-weight-bold h4">${titleAlert}</span>`,
                imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/fail.svg',
                showCancelButton: true,
                confirmButtonText: 'Oke',
                confirmButtonClass: 'btn btn-primary',
                cancelButtonText: nameButton,
                cancelButtonClass:
                  'btn btn-outline-primary bg-white text-primary',
              }).then(result => {
                if (result.isConfirmed) {
                  if (
                    this.dataErrSubmit.message
                    === 'Please Topup to continue your store Order.'
                  ) {
                    this.$router.push('/dashboard-komship')
                  }
                  if (
                    this.dataErrSubmit.message
                    === 'Sorry, your balance is not enough to make a postage payment'
                  ) {
                    this.$router.push('/dashboard-komship')
                  }
                  if (
                    this.dataErrSubmit.message
                    === 'Sorry, there is not enough stock to continue the order'
                  ) {
                    this.$router.push('/produk')
                  }
                }
              })
            } else {
              this.$refs['modal-error-store-order'].show()
            }
          })
      } else {
        this.$swal({
          title: '<span class="font-weight-bold h4">Tidak Boleh Ada Field Yang Kosong!</span>',
          imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/fail.svg',
          confirmButtonText: 'Oke',
          confirmButtonClass: 'btn btn-primary',
        })
      }
    },
    formatCustomerName(e) {
      return String(e).substring(0, 30)
    },
    formatAddressDetail(e) {
      return String(e).substring(0, 185)
    },
    validateInputCustomerName(e) {
      if (e.keyCode === 47 || e.keyCode === 61 || e.keyCode === 58 || e.keyCode === 59) {
        e.preventDefault()
        this.messageErrorLengthCustomerName = true
      } else {
        this.messageErrorLengthCustomerName = false
      }
    },
    validateInputAddressDetail(e) {
      if (e.keyCode === 47 || e.keyCode === 61 || e.keyCode === 58 || e.keyCode === 59) {
        e.preventDefault()
        this.messageErrorAddressDetail = true
      } else {
        this.messageErrorAddressDetail = false
      }
    },
    updateEditMode() {
      this.$emit('changeValueEditMode')
    },
    async fetchDataDetailOrder() {
      this.loadingEditOrder = true
      await this.$http_komship.get(`/v1/order/${this.profile.partner_id}/detail/${this.idOrder}`)
        .then(async response => {
          const { data } = response.data
          this.dateOrder = data.order_date
          this.weight = data.total_weight.toFixed(2)
          this.shippingCost = data.shipping_cost
          this.isMassOrder = data.is_mass_order
          this.customerName = data.customer_name
          this.valuePhone = data.customer_phone
          this.orderId = data.order_id
          this.paymentMethod = data.payment_method
          this.customerAddress = data.customer_address_edit
          this.grandTotal = data.grandtotal
          this.cashback = data.shipping_cashback
          this.cashbackPercentage = data.cashback_to
          this.serviceFee = Math.round(data.service_fee)
          this.serviceFeePercentage = data.service_fee_to
          this.netProfit = data.net_profit
          this.subTotal = data.subtotal
          this.discount = data.discount
          this.additionalCost = data.additional_cost
          this.orderNotes = data.order_notes
          this.shipping = `${data.shipping} - ${data.shipment_alias} - ${IDR(data.shipping_cost)}`
          this.address = data.address_partner !== null ? data.address_partner[0].name : data.warehouse_name
          this.rekening = data.bank_account_name
          this.destination = data.tracking_address_customer
          this.CustomLabelId = data.custom_label_id
          this.soldById = data.tracking_sales_id
          this.checkedInsurance = data.is_insurance === 1
          this.insurancePrice = data.insurance_fee
          this.itemOrder = data.product
          if (this.discount !== 0) {
            this.potonganSaldo = true
          }
          if (this.additionalCost !== 0) {
            this.biayaLain = true
          }
          if (this.profile.partner_is_custom_label) {
            this.getCustomLabel()
          }
          if (this.profile.partner_is_tracking_sales) {
            this.getSalesTrackingList()
          }
          this.loadingEditOrder = false
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          })
          this.loadingEditOrder = false
        })
    },
    refreshPage() {
      window.location.reload()
    },
    setCustomerPhone(newVal) {
      this.customerPhone = newVal.customerPhone
      this.requireCustomerPhone = newVal.requireCustomerPhone
    },
  },
}
